/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

.main { overflow: hidden; padding: 14px 0 105px; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.main { padding: 15px 0; }
}