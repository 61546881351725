/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

.form-row ~ .form-row { margin-top: 9px; }

.form-label { display: block; padding-bottom: 5px; font-size: 13px; }

.textarea,
.field { display: block; width: 100%; padding: 4px; border: 1px solid #cfcfcf; font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 12px; }

.textarea { height: 38px; }

.textarea,
.field {
	background: rgb(255,255,255); /* Old browsers */
	background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(238,238,238,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(238,238,238,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(238,238,238,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
}

.form-actions { margin-top: 15px; text-align: right; }

.form-btn { display: inline-block; padding: 4px 30px; border: 1px solid #1a5189; font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 12px; color: #fff; -webkit-appearance: none; }

.form-btn {
	background: rgb(74,127,181); /* Old browsers */
	background: -webkit-linear-gradient(top,  rgba(74,127,181,1) 0%,rgba(76,129,183,1) 5%,rgba(76,129,183,1) 19%,rgba(58,111,165,1) 81%,rgba(55,108,162,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  rgba(74,127,181,1) 0%,rgba(76,129,183,1) 5%,rgba(76,129,183,1) 19%,rgba(58,111,165,1) 81%,rgba(55,108,162,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(74,127,181,1) 0%,rgba(76,129,183,1) 5%,rgba(76,129,183,1) 19%,rgba(58,111,165,1) 81%,rgba(55,108,162,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a7fb5', endColorstr='#376ca2',GradientType=0 ); /* IE6-9 */
}

.form-btn:hover {
	background: rgb(55,108,162); /* Old browsers */
	background: -webkit-linear-gradient(top,  rgba(55,108,162,1) 0%,rgba(58,111,165,1) 19%,rgba(76,129,183,1) 81%,rgba(76,129,183,1) 95%,rgba(74,127,181,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  rgba(55,108,162,1) 0%,rgba(58,111,165,1) 19%,rgba(76,129,183,1) 81%,rgba(76,129,183,1) 95%,rgba(74,127,181,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(55,108,162,1) 0%,rgba(58,111,165,1) 19%,rgba(76,129,183,1) 81%,rgba(76,129,183,1) 95%,rgba(74,127,181,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#376ca2', endColorstr='#4a7fb5',GradientType=0 ); /* IE6-9 */
}

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.form-btn { display: block; width: 100%; }
}