/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content { float: left; width: 62%; padding-top: 10px; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.content { float: none; width: auto; padding-top: 0; }
}