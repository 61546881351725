/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer { padding: 19px 0; background: #9db4d0; font-size: 12px; color: #fff; text-align: center; }

/*  Footer Nav  */
.footer-nav ul { list-style: none outside none; }
.footer-nav li { position: relative; display: inline-block; vertical-align: top; }
.footer-nav li ~ li { padding-left: 15px; margin-left: 10px; }
.footer-nav li ~ li:before { position: absolute; top: 3px; left: 0; height: 11px; border-left: 1px solid #fff; content: ''; }

/*  Footer Contacts  */
.footer-contacts { margin-top: 10px; text-align: left; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.footer { padding: 10px 0; }

	.footer-nav li { display: block; }
	.footer-nav li ~ li { padding-left: 0; margin: 3px 0 0; }
	.footer-nav li ~ li:before { display: none; } 

	.footer-contacts { margin: 15px 0; text-align: center; }
}