/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article .article-head { margin-bottom: 22px; }

.article .article-title { color: #243f60; }

.article .article-head h3 { line-height: 1.2; color: #d56710; }

.article .article-entry { line-height: 1.7; }

.article p { margin-bottom: 25px; }

.article ul { padding-left: 31px; }