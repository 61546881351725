/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* { padding: 0; margin: 0; outline: 0; box-sizing: border-box; }

*:before, 
*:after { box-sizing: border-box; }

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section { display: block; }

html,
body { height: 100%; }

html { overflow-y: scroll; text-size-adjust: 100%; }

body { font-smoothing: antialiased; }

img { border: 0; vertical-align: middle; }
strong { font-weight: bold; }
small { font-size: 80%; }

button,
input,
select,
textarea { font-family: inherit; font-size: inherit; color: inherit; font-smoothing: antialiased; }

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="submit"],
textarea,
button { -webkit-appearance: none; }

input::placeholder,
textarea::placeholder { opacity: 1; color: inherit; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"]
label[for] { cursor: pointer; }

button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }

button { overflow: visible; }
textarea { overflow: auto; resize: none; }

table { border-collapse: collapse; border-spacing: 0; width: 100%; }
th,
td { padding: 0; }
