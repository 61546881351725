/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav { text-align: right; }
.nav li { position: relative; display: inline-block; vertical-align: top; }
.nav li ~ li { padding-left: 25px; margin-left: 17px; }
.nav li ~ li:before { position: absolute; top: 3px; left: 0; height: 14px; border-left: 1px solid #fff; content: ''; }

.nav a { color: #d2e2eb; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-tablet-portrait {
	.nav { text-align: center; }
	.nav li ~ li { padding-left: 0; margin-left: 0; }
	.nav li ~ li,
	.nav li { padding: 0 15px; margin-bottom: 3px; }

	.nav li ~ li:before { display: none; }
}