/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro { padding: 40px 0 48px; background: url(images/header.jpg) no-repeat 0 0; color: #fff; background-size: cover; }

.intro-body { overflow: hidden; }

.intro-content { float: left; width: 455px; text-align: center; }
.intro-content h1 { margin-bottom: 25px; line-height: 0.9; }
.intro-content p { margin-bottom: 15px; font-size: 17px; line-height: 2; }
.intro-content h4 { color: #d56710; }
.intro-content h4 a { color: inherit; }
.intro-content h4 a:hover { text-decoration: none; }

.intro-image { float: right; padding-top: 3px; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-tablet-portrait {
	.intro { padding: 20px 0; }

	.intro-content { float: none; width: auto; }

	.intro-image { float: none; text-align: center; }
	.intro-image img { max-width: 100%; height: auto; margin-top: 20px; }
}

$breakpoint-mobile {
	.intro-content p,
	.intro-content h1 { margin-bottom: 10px; }
}