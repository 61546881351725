/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */

.widgets { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Widget Contacts
\* ------------------------------------------------------------ */

.widget-contacts .widget-head { padding-left: 20px; margin-bottom: 2px; text-align: center; }
.widget-contacts .widget-head p { margin-left: -20px; font-size: 13px; text-align: left; }

.widget-contacts .widget-title { color: #243f60; }

.widget-contacts h3 { margin-bottom: 15px; color: #d56710; }
.widget-contacts h3 a { color: inherit; }
.widget-contacts h3 a:hover { text-decoration: none; }

.widget-contacts .map { position: relative; padding-top: 138%; }
.widget-contacts .map iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/* ------------------------------------------------------------ *\
	Widget Form
\* ------------------------------------------------------------ */

.widget-form { margin-top: 13px; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.widget-contacts .widget-head { padding-left: 0; }
	.widget-contacts .widget-head p { margin-left: 0; }


	.widget-contacts .map { padding-top: 55%; }
}