/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar { position: relative; float: right; width: 35%; padding: 0 18px 0 20px; }
.sidebar:before { position: absolute; top: 0; left: -8px; width: 8px; height: 438px; background: url(images/shadow.png) no-repeat 0 0; content: ''; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	.sidebar { float: none; width: auto; padding: 0; margin-top: 20px; }
	.sidebar:before { display: none; }
}