/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */
.clear:after { content: ''; line-height: 0; display: table; clear: both; }


/*  Notext  */
.notext { font-size: 0; line-height: 0; text-indent: -4000px; }
