@import '_settings.css';

/* ------------------------------------------------------------ *\
	1. Generic
\* ------------------------------------------------------------ */

@import '_generic.reset.css';

@import '_generic.fonts.css';

@import '_generic.base.css';

@import '_generic.helpers.css';

/* ------------------------------------------------------------ *\
	2. Regions
\* ------------------------------------------------------------ */

@import '_region.wrapper.css';

@import '_region.container.css';

@import '_region.shell.css';

@import '_region.header.css';

@import '_region.main.css';

@import '_region.footer.css';

@import '_region.content.css';

@import '_region.sidebar.css';

/* ------------------------------------------------------------ *\
	3. Modules
\* ------------------------------------------------------------ */

@import '_module.logo.css';

@import '_module.btn.css';

@import '_module.intro.css';

@import '_module.section.css';

@import '_module.form.css';

@import '_module.nav.css';

@import '_module.article.css';

@import '_module.widgets.css';

@import '_module.form-elements.css';
