/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { min-width: 320px; background: $base-background-color; font-family: $font-family-sans-serif; font-size: $font-size-base; line-height: $line-height-base; color: $base-color; }

a { color: inherit; text-decoration: none; }
a:hover { text-decoration: underline; }

h1,
h2,
h3,
h4,
h5,
h6 { font-weight: normal; }

h1 { font-size: 38px; }
h2 { font-size: 32px; }
h3 { font-size: 29px; }
h4 { font-size: 25px; }
h5 { font-size: 20px; }
h6 { font-size: 18px; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-mobile {
	h1 { font-size: 34px; }
	h2 { font-size: 28px; }
	h3 { font-size: 25px; }
	h4 { font-size: 20px; }
	h5 { font-size: 18px; }
	h6 { font-size: 16px; }	
}