/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { max-width: $shell-max-width; margin: auto; }

/* ------------------------------------------------------------ *\
	Media Queries
\* ------------------------------------------------------------ */

$breakpoint-tablet-portrait {
	.shell { padding: 0 5px; }
}
